/*!
 * @file ScrollMagic jQuery plugin.
 *
 * requires: jQuery ~1.11 or ~2.1
 */
/**
 * This plugin is meant to be used in conjunction with jQuery.  
 * It enables ScrollMagic to make use of jQuery's advanced selector engine (sizzle) for all elements supplied to ScrollMagic objects, like scroll containers or trigger elements.  
 * ScrollMagic also accepts jQuery elements for all methods that expect references to DOM elements. Please note, that in most cases the first element of the matched set will be used.
 * 
 * Additionally it provides the ScrollMagic object within the jQuery namespace, so it can be accessed using `$.ScrollMagic`.
 *
 * In contrast to most other plugins it does not offer new API additions for ScrollMagic.
 *
 * To have access to this extension, please include `plugins/jquery.ScrollMagic.js`.
 * @example
 * // create a new scene making use of jQuery's advanced selector engine
 * var scene = new $.ScrollMagic.Scene({
 *   triggerElement: "#parent div.trigger[attr='thisone']:not(.notthisone)"
 * });
 * @requires {@link http://jquery.com/|jQuery ~1.11 or ~2.1}
 * @mixin framework.jQuery
 */
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['ScrollMagic', 'jquery'], factory);
    } else if (typeof exports === 'object') {
        // CommonJS
        factory(require('scrollmagic'), require('jquery'));
    } else {
        // Browser global
        factory(root.ScrollMagic, root.jQuery);
    }
}(this, function (ScrollMagic, $) {
    "use strict";
    var NAMESPACE = "jquery.ScrollMagic";

    // (BUILD) - REMOVE IN MINIFY - START
    var
        console = window.console || {},
        err = Function.prototype.bind.call(console.error || console.log || function() {}, console);
    if (!ScrollMagic) {
        err("(" + NAMESPACE + ") -> ERROR: The ScrollMagic main module could not be found. Please make sure it's loaded before this plugin or use an asynchronous loader like requirejs.");
    }
    if (!$) {
        err("(" + NAMESPACE + ") -> ERROR: jQuery could not be found. Please make sure it's loaded before ScrollMagic or use an asynchronous loader like requirejs.");
    }
    // (BUILD) - REMOVE IN MINIFY - END

    ScrollMagic._util.get.elements = function (selector) {
        return $(selector).toArray();
    };
    ScrollMagic._util.addClass = function (elem, classname) {
        $(elem).addClass(classname);
    };
    ScrollMagic._util.removeClass = function (elem, classname) {
        $(elem).removeClass(classname);
    };
    $.ScrollMagic = ScrollMagic;
}));